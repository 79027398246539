import { Injectable } from '@angular/core';
import { Api } from 'src/app/shared/constants/api';
import { NetworkService } from 'src/app/shared/services/network/network.service';

@Injectable()
export class MasterDataService {

  constructor(
    private networkService: NetworkService, 
  ) { }

  public get boards$() {
    return this.networkService.get<any[]>(Api.CMS_MD_BOARDS);
  }

  public get exams$() {
    return this.networkService.get<any[]>(Api.CMS_MD_EXAMS);
  }

  public get standards$() {
    return this.networkService.get<any[]>(Api.CMS_MD_STANDARDS);
  }

  public get subjects$() {
    return this.networkService.get<any[]>(Api.CMS_MD_SUBJECTS);
  }

  public get courses$() {
    return this.networkService.get<any[]>(Api.CMS_MD_COURSES_ALL);
  }

  public get courseTrees$() {
    return this.networkService.get<any[]>(Api.CMS_MD_COURSE_TREE_ALL);
  }

  public addCourse$(payload) {
    return this.networkService.post<any>(Api.CMS_MD_COURSES_ADD, null, payload);
  }

  public addCourseTree$(payload) {
    return this.networkService.post<any>(Api.CMS_MD_COURSE_TREE_ADD, null, payload);
  }

  public updateCourseTree$(payload) {
    return this.networkService.post<any>(Api.CMS_MD_COURSE_TREE_UPDATE, null, payload);
  }

  public addChapter$(payload) {
    return this.networkService.post<any>(Api.CMS_MD_CHAPTER_ADD, null, payload);
  }

  public addConcept$(payload) {
    return this.networkService.post<any>(Api.CMS_MD_CONCEPT_ADD, null, payload);
  }

  public addCompetitiveExam$(payload) {
    return this.networkService.post<any>(Api.CMS_MD_COMPETITIVE_EXAM_ADD, null, payload);
  }

}
