/**
 * @fileoverview added by tsickle
 * @suppress {checkTypes} checked by tsc
 */
import { Directive, ElementRef, Input, Output, EventEmitter, PLATFORM_ID, Inject } from '@angular/core';
import { CustomEvent } from './custom-event-polyfill';
import { isPlatformBrowser } from '@angular/common';
/**
 * @record
 */
export function MaterializeAction() { }
function MaterializeAction_tsickle_Closure_declarations() {
    /** @type {?} */
    MaterializeAction.prototype.action;
    /** @type {?} */
    MaterializeAction.prototype.params;
}
var MaterializeDirective = /** @class */ (function () {
    function MaterializeDirective(platformId, _el) {
        this.platformId = platformId;
        this._el = _el;
        this._params = [];
        this._functionName = null;
        this.previousValue = null;
        this.previousDisabled = false;
        this._waitFunction = {};
        this.isBrowser = isPlatformBrowser(this.platformId);
        this.changeListenerShouldBeAdded = true;
        this.init = new EventEmitter();
        this.initialized = false;
    }
    Object.defineProperty(MaterializeDirective.prototype, "materialize", {
        set: /**
         * @param {?} functionName
         * @return {?}
         */
        function (functionName) {
            var _this = this;
            this._functionName = functionName;
            if (this.isBrowser) {
                window.setTimeout(function () {
                    _this.performElementUpdates();
                    _this.performElementInit();
                }, 1);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MaterializeDirective.prototype, "materializeParams", {
        set: /**
         * @param {?} params
         * @return {?}
         */
        function (params) {
            this._params = params;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MaterializeDirective.prototype, "materializeActions", {
        set: /**
         * @param {?} actions
         * @return {?}
         */
        function (actions) {
            var _this = this;
            if (this.isBrowser) {
                actions.subscribe(function (action) {
                    window.setTimeout(function () {
                        if (typeof action === "string") {
                            _this.performElementAction(action);
                        }
                        else {
                            _this.performElementAction(action.action, action.params);
                        }
                    }, 1);
                });
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MaterializeDirective.prototype, "materializeSelectOptions", {
        set: /**
         * @param {?} options
         * @return {?}
         */
        function (options) {
        },
        enumerable: true,
        configurable: true
    });
    /**
     * @return {?}
     */
    MaterializeDirective.prototype.ngAfterViewInit = /**
     * @return {?}
     */
    function () {
        if (this.isBrowser) {
            this.performElementUpdates();
        }
    };
    /**
     * @param {?=} _unused
     * @return {?}
     */
    MaterializeDirective.prototype.ngOnChanges = /**
     * @param {?=} _unused
     * @return {?}
     */
    function (_unused) {
        var _this = this;
        if (this.isBrowser) {
            if (this.isSelect()) {
                var /** @type {?} */ nativeElement = this._el.nativeElement;
                var /** @type {?} */ jQueryElement = $(nativeElement);
                // run performElementInit() only if dropdown closed
                // otherwise the dropdown closes unexpected
                if (!jQueryElement.attr("multiple") || jQueryElement.parent().find("input.active").length === 0) {
                    setTimeout(function () { return _this.performElementInit(); }, 10);
                }
            }
        }
    };
    /**
     * @return {?}
     */
    MaterializeDirective.prototype.ngOnDestroy = /**
     * @return {?}
     */
    function () {
        if (this.isBrowser) {
            this.performElementRemotion();
        }
    };
    /**
     * @return {?}
     */
    MaterializeDirective.prototype.ngDoCheck = /**
     * @return {?}
     */
    function () {
        if (this.isBrowser) {
            var /** @type {?} */ nativeElement = this._el.nativeElement;
            var /** @type {?} */ jQueryElement = $(nativeElement);
            if (this.isSelect()) {
                var /** @type {?} */ shouldUpdate = false;
                if (nativeElement.disabled != this.previousDisabled) {
                    this.previousDisabled = nativeElement.disabled;
                    shouldUpdate = true;
                }
                if (!jQueryElement.attr("multiple") && nativeElement.value != this.previousValue) {
                    // handle select changes of the model
                    this.previousValue = nativeElement.value;
                    shouldUpdate = true;
                }
                if (shouldUpdate) {
                    this.performElementInit();
                }
            }
            else if (this.isTextarea()) {
                if (nativeElement.value != this.previousValue) {
                    this.previousValue = nativeElement.value;
                    this.performElementUpdates();
                }
            }
        }
        return false;
    };
    /**
     * @return {?}
     */
    MaterializeDirective.prototype.performElementRemotion = /**
     * @return {?}
     */
    function () {
        try {
            this.performElementAction("destroy");
        }
        catch (/** @type {?} */ e) {
            // can crash if not properly initialized
            console.error(e);
        }
    };
    /**
     * @param {?} action
     * @param {?=} params
     * @return {?}
     */
    MaterializeDirective.prototype.performElementAction = /**
     * @param {?} action
     * @param {?=} params
     * @return {?}
     */
    function (action, params) {
        if (params === void 0) { params = []; }
        var /** @type {?} */ instance = this.getInstance();
        if (!!instance && !!instance[action])
            instance[action].apply(instance, params);
    };
    /**
     * @return {?}
     */
    MaterializeDirective.prototype.performElementUpdates = /**
     * @return {?}
     */
    function () {
        // it should have been created by now, but confirm anyway
        if (M && M.updateTextFields) {
            M.updateTextFields();
        }
        // handle select changes from the HTML
        if (this.isSelect() && this.changeListenerShouldBeAdded) {
            var /** @type {?} */ nativeElement_1 = this._el.nativeElement;
            var /** @type {?} */ jQueryElement = $(nativeElement_1);
            jQueryElement.on("change", function (e) {
                if (!e.originalEvent || !e.originalEvent.internalToMaterialize) {
                    var /** @type {?} */ event_1 = document.createEvent("CustomEvent");
                    //if (jQueryElement.attr("multiple")) {
                    //event.initCustomEvent("input",false,false,undefined);
                    //}
                    //else {
                    //if (jQueryElement.attr("multiple")) {
                    //event.initCustomEvent("input",false,false,undefined);
                    //}
                    //else {
                    event_1.initCustomEvent("change", false, false, undefined);
                    //}
                    //}
                    event_1.internalToMaterialize = true;
                    nativeElement_1.dispatchEvent(event_1);
                }
            });
            this.changeListenerShouldBeAdded = false;
        }
        if (this.isAutocomplete()) {
            var /** @type {?} */ nativeElement_2 = this._el.nativeElement;
            var /** @type {?} */ jQueryElement = $(nativeElement_2);
            jQueryElement.on("change", function (e) { return nativeElement_2.dispatchEvent((/** @type {?} */ (CustomEvent("input")))); });
        }
        if (this.isDatePicker() || this.isTimePicker()) {
            var /** @type {?} */ nativeElement_3 = this._el.nativeElement;
            var /** @type {?} */ jQueryElement = $(nativeElement_3);
            jQueryElement.on("change", function (e) { return nativeElement_3.dispatchEvent((/** @type {?} */ (CustomEvent("input")))); });
        }
        if (this.isChips()) {
            var /** @type {?} */ nativeElement_4 = this._el.nativeElement;
            var /** @type {?} */ jQueryElement = $(nativeElement_4);
            jQueryElement.on("chip.add", function (e, chip) { return nativeElement_4.dispatchEvent((/** @type {?} */ (CustomEvent("chip.add", chip)))); });
            jQueryElement.on("chip.delete", function (e, chip) { return nativeElement_4.dispatchEvent((/** @type {?} */ (CustomEvent("chip.delete", chip)))); });
            jQueryElement.on("chip.select", function (e, chip) { return nativeElement_4.dispatchEvent((/** @type {?} */ (CustomEvent("chip.select", chip)))); });
        }
        if (this.isTextarea()) {
            this._el.nativeElement.dispatchEvent((/** @type {?} */ (CustomEvent("autoresize", {
                bubbles: true,
                cancelable: false,
                detail: undefined
            }))));
        }
    };
    /**
     * @param {?=} functionName
     * @param {?=} params
     * @return {?}
     */
    MaterializeDirective.prototype.performElementInit = /**
     * @param {?=} functionName
     * @param {?=} params
     * @return {?}
     */
    function (functionName, params) {
        var _this = this;
        if (functionName === void 0) { functionName = this._functionName; }
        if (params === void 0) { params = this._params; }
        if (this._waitFunction[functionName]) {
            return;
        }
        this._waitFunction[functionName] = true;
        $(document).ready(function () {
            var _a;
            // console.log(functionName, params, this._el.nativeElement);
            // console.log(functionName, params, this._el.nativeElement);
            _this._waitFunction[functionName] = false;
            if (functionName) {
                var /** @type {?} */ jQueryElement = $(_this._el.nativeElement);
                if (!!M[functionName] && !!M[functionName].init) {
                    if (params && params instanceof Array && params.length > 0) {
                        _this.instance = (_a = M[functionName]).init.apply(_a, [_this._el.nativeElement].concat(params));
                    }
                    else {
                        _this.instance = M[functionName].init(_this._el.nativeElement);
                    }
                }
                else {
                    throw new Error("Couldn't find materialize function ''" + functionName + "' on element or the global Materialize object.");
                }
                if (!_this.initialized) {
                    _this.initialized = true;
                    _this.init.emit();
                }
            }
        });
    };
    /**
     * @return {?}
     */
    MaterializeDirective.prototype.getInstance = /**
     * @return {?}
     */
    function () {
        var /** @type {?} */ elem = this._el.nativeElement;
        if (!!this._functionName && !!M[this._functionName] && !!M[this._functionName].getInstance)
            return M[this._functionName].getInstance(elem);
        return null;
    };
    /**
     * @return {?}
     */
    MaterializeDirective.prototype.isTooltip = /**
     * @return {?}
     */
    function () {
        return (this._functionName && this._functionName === "Tooltip");
    };
    /**
     * @return {?}
     */
    MaterializeDirective.prototype.isSelect = /**
     * @return {?}
     */
    function () {
        return (this._functionName && this._functionName === "FormSelect");
    };
    /**
     * @return {?}
     */
    MaterializeDirective.prototype.isDatePicker = /**
     * @return {?}
     */
    function () {
        return (this._functionName && this._functionName === "Datepicker");
    };
    /**
     * @return {?}
     */
    MaterializeDirective.prototype.isTimePicker = /**
     * @return {?}
     */
    function () {
        return (this._functionName && this._functionName === "Timepicker");
    };
    /**
     * @return {?}
     */
    MaterializeDirective.prototype.isChips = /**
     * @return {?}
     */
    function () {
        return (this._functionName && this._functionName === "Chips");
    };
    /**
     * @return {?}
     */
    MaterializeDirective.prototype.isAutocomplete = /**
     * @return {?}
     */
    function () {
        return (this._functionName && this._functionName === "Autocomplete");
    };
    /**
     * @return {?}
     */
    MaterializeDirective.prototype.isTextarea = /**
     * @return {?}
     */
    function () {
        return this._el.nativeElement.nodeName == "TEXTAREA";
    };
    MaterializeDirective.decorators = [
        { type: Directive, args: [{
                    selector: '[materialize]'
                },] },
    ];
    /** @nocollapse */
    MaterializeDirective.ctorParameters = function () { return [
        { type: Object, decorators: [{ type: Inject, args: [PLATFORM_ID,] },] },
        { type: ElementRef, },
    ]; };
    MaterializeDirective.propDecorators = {
        "init": [{ type: Output },],
        "materialize": [{ type: Input },],
        "materializeParams": [{ type: Input },],
        "materializeActions": [{ type: Input },],
        "materializeSelectOptions": [{ type: Input },],
        "ngModel": [{ type: Input },],
    };
    return MaterializeDirective;
}());
export { MaterializeDirective };
function MaterializeDirective_tsickle_Closure_declarations() {
    /** @type {!Array<{type: !Function, args: (undefined|!Array<?>)}>} */
    MaterializeDirective.decorators;
    /**
     * @nocollapse
     * @type {function(): !Array<(null|{type: ?, decorators: (undefined|!Array<{type: !Function, args: (undefined|!Array<?>)}>)})>}
     */
    MaterializeDirective.ctorParameters;
    /** @type {!Object<string,!Array<{type: !Function, args: (undefined|!Array<?>)}>>} */
    MaterializeDirective.propDecorators;
    /** @type {?} */
    MaterializeDirective.prototype._params;
    /** @type {?} */
    MaterializeDirective.prototype._functionName;
    /** @type {?} */
    MaterializeDirective.prototype.previousValue;
    /** @type {?} */
    MaterializeDirective.prototype.previousDisabled;
    /** @type {?} */
    MaterializeDirective.prototype._waitFunction;
    /** @type {?} */
    MaterializeDirective.prototype.instance;
    /** @type {?} */
    MaterializeDirective.prototype.isBrowser;
    /** @type {?} */
    MaterializeDirective.prototype.changeListenerShouldBeAdded;
    /** @type {?} */
    MaterializeDirective.prototype.init;
    /** @type {?} */
    MaterializeDirective.prototype.initialized;
    /** @type {?} */
    MaterializeDirective.prototype.ngModel;
    /** @type {?} */
    MaterializeDirective.prototype.platformId;
    /** @type {?} */
    MaterializeDirective.prototype._el;
}
